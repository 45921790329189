<template>
  <div>
    <FlowResolver
      :flow="flow"
      :router="$router"
      :route="$route"
      origin="user"
      :lang="language.lang"
      @flowData="updateFlow"
      v-if="flow"
    />
  </div>
</template>

<script>
import { FlowResolver, Instruments } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  name: 'Document',
  components: {
    FlowResolver
  },
  data () {
    return {
      flow: null,
      loading: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.loading = true
      const answerId = this.$route.params.key
      this.flow = await Instruments.getInstrumentByAnwserId(answerId)
      this.loading = false
    },
    async updateFlow ($event) {
      const flow = {
        ...$event.flow,
        status: 'COMPLETED',
        answer: { ...$event.block.answer }
      }

      await Instruments.updateInstruments(flow)
    }
  },
  computed: {
    ...mapGetters({
      language: 'language'
    })
  },
  watch: {
    loading () {
      if (this.loading) {
        this.$loader.handleShowLoader({
          lang: this.$store.getters.language.lang,
          type: 'main-transparent',
          key: 'NO_MESSAGE'
        })
      } else {
        this.$loader.handleRemoveLoader()
      }
    }
  }
}
</script>

<style lang="sass" scoped></style>

import { render, staticRenderFns } from "./SharedAccountDetail.vue?vue&type=template&id=792c0e04&scoped=true"
import script from "./SharedAccountDetail.vue?vue&type=script&lang=js"
export * from "./SharedAccountDetail.vue?vue&type=script&lang=js"
import style0 from "./SharedAccountDetail.vue?vue&type=style&index=0&id=792c0e04&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "792c0e04",
  null
  
)

export default component.exports
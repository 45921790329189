<template>
  <PageContainer
    :title="$translations['shared-account']['admin-invite'].title"
    :back="back"
  >
    <template v-slot:content>
      <div class="page-segment">
        <div
          class="page-segment-content"
          :class="responsiveClass('', 'margin-y')"
        >
          <SharedAccountForm :form="form" @form="form = $event" />

          <Button
            :class="responsiveClass('margin-top-lg-2', 'margin-top-lg-3')"
            :text="
              $translations['shared-account']['admin-invite'].buttons.action
            "
            :disable="!form.name || !form.email || !form.role"
            @clicked="handlerSubmit"
          />
          <Button
            class="margin-top-s"
            type="outline"
            :text="
              $translations['shared-account']['admin-invite'].buttons.close
            "
            @clicked="handlerCancel"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer, Button, ResponsiveMixin } from '@seliaco/red-panda'
import SharedAccountForm from './form/SharedAccountForm.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SharedAccountInvitation',
  components: { PageContainer, Button, SharedAccountForm },
  mixins: [ResponsiveMixin],
  props: {
    email: String
  },
  data () {
    const back = {
      callback: () => this.handlerClose()
    }

    return {
      back,
      form: {
        name: '',
        email: '',
        role: ''
      }
    }
  },
  created () {
    if (!this.member) {
      this.$router.replace({ name: 'SharedAccounts' })
    } else {
      this.init()
    }
  },
  methods: {
    async init () {
      const lastName = this.member.meta?.last_name || ''

      const name = this.member.meta.first_name + ' ' + lastName

      this.form = {
        name,
        email: this.member.meta.email,
        role: this.member.role
      }
    },
    handlerSubmit () {
      const body = {
        first_name: this.form.name,
        email: this.form.email,
        role: this.form.role
      }

      this.$store
        .dispatch('sharedAccount/update', {
          sharedAccountMemberId: this.member.id,
          body: body
        })
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations['shared-account'].toast[
              'new-invite-success'
            ]
          })
        })
        .finally(() => {
          this.handlerClose()
        })
    },
    handlerCancel () {
      this.$store
        .dispatch('sharedAccount/remove', {
          sharedAccountMemberId: this.member.id
        })
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations['shared-account'].toast[
              'cancel-invite-success'
            ]
          })
        })
        .finally(() => {
          this.handlerClose()
        })
    },
    handlerClose () {
      this.$router.replace({ name: 'SharedAccounts' })
    }
  },
  computed: {
    ...mapGetters({
      member: 'sharedAccount/member'
    })
  }
}
</script>

<template>
  <PageContainer
    :title="$translations['shared-account'].title"
    :backEnabled="false"
    :action="action"
  >
    <template v-slot:content>
      <div
        class="page-segment"
        :class="responsiveClass1040('margin-top-s', '')"
        v-if="userRole"
      >
        <div class="page-segment-content">
          <div
            class="heading-small margin-bottom"
            v-text="$translations['shared-account']['member-title']"
          />
          <div>
            <PersonCard
              :container-class="containerClass(m)"
              class="margin-bottom-s"
              :image="m.meta.profile_picture"
              :title="titleText(m.meta)"
              :titleChip="titleChip(m)"
              :firstLine="role(m.role)"
              :middleLineAction="removeText(m)"
              :middleLineChip="pendingChip(m.status)"
              :show-action="handlerAction(m)"
              @middle-line-action-clicked="handlerRemove(m)"
              @clicked="goProfile(m)"
              v-for="(m, i) in members$"
              :key="i"
            />
          </div>
        </div>
      </div>

      <div class="page-segment margin-top" v-if="userRole">
        <div class="page-segment-content">
          <div
            class="shared-account-btn-add section-container-white margin-bottom"
            v-if="userRole?.role === 'ADMIN'"
            @click="handlerAdd"
          >
            <span class="icon-person-add-on icon-display text-purple" />
            <span
              class="content-title"
              v-text="$translations['shared-account']['add-member']"
            />
          </div>
          <div class="shared-account-btn-remove" @click="handlerLeave">
            <span class="text-red icon-display" :class="iconLeave" />
            <span class="content-title text-red-110" v-text="deleteText" />
          </div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer, PersonCard, ResponsiveMixin } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  name: 'SharedAccounts',
  mixins: [ResponsiveMixin],
  components: { PageContainer, PersonCard },
  data () {
    const action = {
      icon: 'icon-close',
      callback: () => {
        if (this.$route.query.back) {
          this.$router.replace(this.$route.query.back)
        } else {
          this.$router.replace({ name: 'Home' })
        }
      }
    }

    return {
      action,
      userRole: null
    }
  },
  mounted () {
    if (!this.sharedAccount) {
      this.$router.replace({ name: 'Home' })
    }

    if (this.members.length > 0) {
      this.init()
    } else {
      this.$store.watch(
        () => this.members,
        () => {
          this.init()
        }
      )
    }
  },
  methods: {
    init () {
      this.userRole = this.members.find(
        (member) =>
          member.user?.email === this.user.email ||
          member.meta.email === this.user.email
      )
    },
    handlerAdd () {
      this.$globalEvent.$emit('modal/shared-account-start', {
        showDialog: {
          sharedAccountStartModal: {
            open: true,
            close: false,
            invitation: true
          }
        }
      })
    },
    handlerRemove (m) {
      this.$globalEvent.$emit('modal/shared-account-remove', {
        showDialog: {
          sharedAccountRemoveModal: {
            open: true,
            close: false,
            type: 'remove',
            member: m
          }
        }
      })
    },
    handlerLeave () {
      const props = {
        open: true,
        close: false,
        type: 'leave',
        member: null
      }

      if (this.userRole.role !== 'ADMIN') {
        props.member = this.userRole
      }

      if (this.userRole.role === 'ADMIN') {
        props.type = 'delete'
      }

      this.$globalEvent.$emit('modal/shared-account-remove', {
        showDialog: { sharedAccountRemoveModal: props }
      })
    },
    goProfile (member) {
      if (member.status === 'PENDING') {
        this.$store.commit('sharedAccount/SET_MEMBER', { member })

        this.$router.push({
          name: 'SharedAccountInvitation'
        })
      } else {
        this.$store.dispatch('sharedAccount/getMember', {
          sharedAccountMemberId: member.id
        })
        this.$router.push({
          name: 'SharedAccountDetail',
          params: { id: member.id }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      sharedAccount: 'sharedAccount/sharedAccountId',
      members: 'sharedAccount/members',
      user: 'auth/user',
      settings: 'settings/getSettings',
      language: 'language'
    }),
    members$ () {
      return this.members.slice().sort((member) => {
        if (member.user?.email === this.user.email) {
          return -1
        } else {
          return 1
        }
      })
    },
    containerClass () {
      return (m) => {
        if (
          m.id === this.userRole.id ||
          (this.userRole.role !== 'ADMIN' && m.status === 'PENDING')
        ) {
          return 'section-container-gray'
        }

        return 'section-container-white'
      }
    },
    handlerAction () {
      return (m) => {
        if (
          m.id === this.userRole.id ||
          (this.userRole.role !== 'ADMIN' && m.status === 'PENDING')
        ) {
          return false
        }

        return true
      }
    },
    role () {
      return (role) => {
        let roleLocal = role

        if (roleLocal === 'ADMIN') {
          roleLocal = 'ADULT'
        }

        const roleFind = this.settings.sharedAccountOptions.find(
          (option) => option.value === roleLocal
        )

        return roleFind.label[this.language.lang] || ''
      }
    },
    titleText () {
      return (meta) => {
        const last_name = meta.last_name || ''

        if (meta.id === this.user.id) {
          return `${meta.first_name} ${last_name} ${this.$translations['shared-account'].you}`
        }

        return `${meta.first_name} ${last_name}`
      }
    },
    removeText () {
      return (member) => {
        if (member.role === 'ADMIN' || member.status === 'PENDING') {
          return ''
        }

        if (this.userRole.role !== 'ADMIN') {
          return ''
        }

        return this.$translations['shared-account']['remove-member']
      }
    },
    pendingChip () {
      return (status) => {
        if (status === 'PENDING') {
          return {
            color: 'blue',
            text: this.$translations['shared-account'].confirmation
          }
        }

        return null
      }
    },
    titleChip () {
      return (m) => {
        if (m.role === 'ADMIN') {
          return {
            color: 'purple',
            text: this.$translations['shared-account'].admin
          }
        }

        return null
      }
    },
    iconLeave () {
      if (this.userRole.role === 'ADMIN') {
        return 'icon-trash-off'
      }

      return 'icon-logout'
    },
    deleteText () {
      if (this.userRole.role === 'ADMIN') {
        return this.$translations['shared-account']['delete-group']
      }

      return this.$translations['shared-account'].exit
    }
  }
}
</script>

<style lang="sass" scoped>
.shared-account
  &-btn-add,
  &-btn-remove
    display: flex
    align-items: center
    cursor: pointer

  &-btn-add
    gap: 16px

  &-btn-remove
    gap: 5px
    justify-content: center
</style>

<template>
  <div class="appointments-content">
    <!-- loader -->
    <template v-if="loading">
      <div class="page-segment margin-top">
        <div class="page-segment-content">
          <div class="margin-bottom">
            <Skeleton width="230px" height="30px" borderRadius="16px" />
          </div>
          <div class="margin-bottom">
            <Skeleton width="180px" height="24px" borderRadius="16px" />
          </div>
          <div class="margin-bottom-sm" v-for="index in 3" :key="index">
            <Skeleton width="100%" height="97px" borderRadius="16px" />
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="appointments && !loading">
      <!-- upcoming appointments -->
      <div class="page-segment margin-top" v-if="showUpcomingAppointment">
        <div class="page-segment-content">
          <AppointmentGroup
            :origin="origin"
            :title="$translations.appointments['upcoming-appointments']"
            :appointments="appointments.upcoming"
          />
        </div>
      </div>

      <!-- past appointments -->
      <div class="page-segment margin-top" v-if="showPastAppointment">
        <div class="page-segment-content">
          <AppointmentGroup
            :origin="origin"
            :title="$translations.appointments['past-appointments']"
            :disabled="true"
            :appointments="appointments.past"
          />
        </div>
      </div>
    </template>

    <LoaderSimple class="margin-y" v-if="infinityScroll" />

    <!-- data zero -->
    <DataZeroSimple
      v-if="showDatazero"
      :text="$translations['shared-account'].empty"
      :image="image"
      :buttons="dataZeroButtons"
    />
  </div>
</template>

<script>
import {
  Skeleton,
  LoaderSimple,
  infinityScrollMixin,
  DataZeroSimple,
  PNG
} from '@seliaco/red-panda'
import AppointmentGroup from '../components/AppointmentGroup'
import { mapGetters } from 'vuex'

export default {
  name: 'AppointmentsTab',
  mixins: [infinityScrollMixin],
  components: {
    DataZeroSimple,
    AppointmentGroup,
    Skeleton,
    LoaderSimple
  },
  props: {
    origin: {
      type: String,
      default: 'agenda'
    }
  },
  data () {
    let dataZeroButtons = []

    if (this.origin === 'agenda') {
      dataZeroButtons = [
        {
          text: this.$translations.appointments['empty-state'].buttons[
            'schedule-first-appointment'
          ],
          type: 'block',
          redirect: '/matching',
          callback: () => this.$store.commit('flow/RESET_MATCHING')
        }
      ]
    }

    return {
      dataZeroButtons,
      image: PNG.NoAppointments
    }
  },
  created () {
    this.handleRefreshAppointment()

    if (this.$store.getters['auth/user']) {
      this.getData()
    } else {
      this.$store.watch(
        () => this.$store.getters['auth/user'],
        () => {
          this.getData()
        }
      )
    }
  },
  mounted () {
    if (this.origin === 'shared-account') {
      this.setListener('header-extend-container-content')
    } else {
      this.setListener('appointments-content')
    }
  },
  methods: {
    getData () {
      if (this.origin === 'agenda') {
        this.$store.dispatch('myAppointments/get', {
          page: this.page,
          per_page: 10
        })
      } else {
        this.$store.dispatch('myAppointments/getByPatientId', {
          page: this.page,
          per_page: 10
        })
      }
    },
    handleRefreshAppointment () {
      this.$globalEvent.$on('refresh/appointment', () => {
        if (this.$router.history.current.name === 'Home') {
          this.$store.dispatch('myAppointments/get')
        } else {
          this.getData()
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      appointments: 'myAppointments/getAppointments',
      loading: 'myAppointments/getLoading',
      preventMultipleCallsStore: 'myAppointments/getPreventMultipleCalls',
      user: 'auth/user'
    }),
    isTestMode () {
      return process.env.VUE_APP_MODE === 'DEV'
    },
    showDatazero () {
      return (
        this.appointments &&
        Object.keys(this.appointments.upcoming).length === 0 &&
        Object.keys(this.appointments.past).length === 0
      )
    },
    showPastAppointment () {
      return (
        this.appointments &&
        this.appointments.past &&
        Object.keys(this.appointments.past).length > 0
      )
    },
    showUpcomingAppointment () {
      return (
        this.appointments &&
        this.appointments.upcoming &&
        Object.keys(this.appointments.upcoming).length > 0
      )
    }
  },
  watch: {
    appointments: {
      handler (val) {
        if (val) {
          this.totalOfPages = val.pages
          this.totalCount = val.count

          if (Number(val.pages) === this.page) {
            this.infinityScroll = false
          }
        }
      },
      immediate: true,
      deep: true
    },
    preventMultipleCallsStore: {
      handler (val) {
        this.preventMultipleCalls = val
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.active
  .action-button
    display: block !important
</style>

export const Genders = (translations) => {
  return [
    {
      value: 'FEMALE',
      label: translations.option.gender.female
    },
    {
      value: 'MALE',
      label: translations.option.gender.male
    },
    {
      value: 'NON_BINARY',
      label: translations.option.gender['non-binary']
    },
    {
      value: 'NO_SPECIFY',
      label: translations.option.gender['non-specify']
    },
    {
      value: 'TRANSGENDER_FEMALE',
      label: translations.option.gender['transgender-female']
    },
    {
      value: 'TRANSGENDER_MALE',
      label: translations.option.gender['transgender-male']
    }
  ]
}

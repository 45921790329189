<template>
  <HeaderPageContainer
    :title="$translations['shared-account']['member-history']"
    :back="actions.back"
    :action="actions.action"
  >
    <template v-slot:header-content>
      <div class="page-segment-complete" v-if="member">
        <div class="page-segment-complete-content">
          <div class="shared-account-detail-header">
            <img
              class="shared-account-detail-header-img"
              :src="avatar"
              :alt="title"
            />
            <div class="shared-account-detail-header-content">
              <div class="heading" v-text="title" />
              <div class="body" v-text="memberData" />
              <div class="body" v-text="role" />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:content>
      <AppointmentsTab origin="shared-account" v-if="!loadingSharedAccount" />
    </template>
  </HeaderPageContainer>
</template>

<script>
import {
  HeaderPageContainer,
  ResponsiveMixin,
  calculateAge
} from '@seliaco/red-panda'
import AppointmentsTab from '../agenda/tabs/AppointmentsTab.vue'

import { Genders } from '../../constants/genders'
import { mapGetters } from 'vuex'

export default {
  name: 'SharedAccountDetail',
  components: { HeaderPageContainer, AppointmentsTab },
  mixins: [ResponsiveMixin],
  data () {
    const actions = {
      back: {
        callback: () => this.$router.push({ name: 'SharedAccounts' })
      },
      action: {
        icon: 'icon-close',
        callback: () => this.$router.replace({ name: 'Home' })
      }
    }

    return {
      actions,
      loading: true
    }
  },
  created () {
    if (!this.member) {
      this.$store.dispatch('sharedAccount/getMember', {
        sharedAccountMemberId: this.$route.params.id
      })
    }
  },
  destroyed () {
    this.$store.commit('myAppointments/SET_APPOINTMENTS', {
      appointments: null
    })
  },
  computed: {
    ...mapGetters({
      member: 'sharedAccount/member',
      loadingSharedAccount: 'sharedAccount/loading',
      settings: 'settings/getSettings',
      language: 'language'
    }),
    avatar () {
      return (
        this.member.meta?.profile_picture ||
        require('@/assets/images/avatar.png')
      )
    },
    title () {
      return `${this.member.meta.first_name} ${this.member.meta.last_name}`
    },
    memberData () {
      const gender = Genders(this.$translations).find(
        (i) => i.value === this.member.meta.gender
      ).label

      return `${gender} • ${calculateAge(this.member.meta.birthday)} ${
        this.$translations.general.year
      }`
    },
    role () {
      if (this.member.role === 'ADMIN') {
        return this.$translations['shared-account'].admin
      }

      const roleFind = this.settings.sharedAccountOptions.find(
        (option) => option.value === this.member.role
      )

      return roleFind.label[this.language.lang] || ''
    }
  }
}
</script>

<style lang="sass" scoped>
.shared-account-detail
  &-header
    padding: 16px
    display: flex
    gap: 16px
    align-items: center
    &-img
      width: 96px
      height: 96px
      border-radius: 50%
    &-content
      display: flex
      flex-direction: column
      gap: 4px
</style>

<template>
  <div class="padding-bottom margin-y">
    <div class="heading">
      {{ title }}
    </div>

    <div v-for="(appointmentDay, key) in appointments" :key="key">
      <div class="capitalize heading-small margin-y">
        {{
          $moment(key).locale($store.state.language.lang).format('DD MMMM, ddd')
        }}
      </div>

      <UsersAppointmentCard
        class="margin-bottom"
        v-for="(appointment, index) in appointmentDay"
        :key="index"
        :appointment="mappedAppointment(appointment)"
        :origin="origin"
      />
    </div>

    <div v-for="(conference, key) in conferences" :key="key">
      <div class="capitalize heading-small margin-y">
        {{
          $moment(key).locale($store.state.language.lang).format('DD MMMM, ddd')
        }}
      </div>

      <ConferenceAppointmentCard
        class="margin-bottom"
        v-for="(i, index) in conference"
        :key="index"
        :conference="i"
        :active="false"
      />
    </div>
  </div>
</template>

<script>
import UsersAppointmentCard from '@/components/appointment/AppointmentCard'
import ConferenceAppointmentCard from '@/components/appointment/ConferenceCard'

export default {
  name: 'AppointmentGroup',
  components: { UsersAppointmentCard, ConferenceAppointmentCard },
  props: {
    title: String,
    origin: String,
    appointments: {},
    conferences: {}
  },
  methods: {
    mappedAppointment (appointment) {
      if (appointment.service.service_type) {
        appointment.service = {
          ...appointment.service.service_type
        }
      }

      return appointment
    }
  }
}
</script>
